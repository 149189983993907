/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    // Fetch the doctors data from JSON or API
    fetch("/data.json")
      .then((response) => response.json())
      .then((data) => setDoctors(data.doctors));
  }, []);

  return (
    <div className="doctors-area with-bg-transparent pb-125">
      <div className="container">
        <div className="section-title" data-cues="slideInUp">
          <h2>Our Experienced Dentists</h2>
        </div>
        <div className="row justify-content-center" data-cues="slideInUp">
          {doctors.map((doctor) => (
            <div className="col-xl-4 col-md-6" key={doctor.id}>
              <div className="doctors-card">
                <div className="doctor-image">
                  <Link to={`/dentist-profile/${doctor.id}`}>
                    <img src={doctor.image} alt={doctor.name} />
                  </Link>
                </div>
                <div className="doctor-content">
                  <h3>
                    <Link to={`/dentist-profile/${doctor.id}`}>
                      {doctor.name}
                    </Link>
                  </h3>
                  <span>{doctor.specialty}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Doctors;
